import { useI18nDateTimeFormats, useI18nNumberFormats, FALLBACK_LOCALE_POLICY } from '@yescapa-dev/ysc-components/i18n'

export default defineI18nConfig(() => {
  // Fallback locale map
  const fallbackLocale = FALLBACK_LOCALE_POLICY

  // Number formats
  const numberFormats = useI18nNumberFormats()

  // Datetime formats
  const extraDateTimeFormats = {
    'Y.numeric M.long': {
      year: 'numeric',
      month: 'long',
    },
  }
  const defaultDateTimeFormats = useI18nDateTimeFormats()
  const datetimeFormats = Object.entries<typeof defaultDateTimeFormats>(defaultDateTimeFormats).reduce((acc, [localeCode, formats]) => {
    acc[localeCode] = {
      ...formats,
      extraDateTimeFormats,
    }
    return acc
  }, {} as typeof defaultDateTimeFormats)

  // vue-i18 config
  return {
    legacy: false,
    fallbackLocale,
    datetimeFormats,
    numberFormats,
    missingWarn: false,
    warnHtmlMessage: false,
    fallbackWarn: false,
  }
})
